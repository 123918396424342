import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentURL='';
  LangcurrentURL: string;
  constructor() {
    this.currentURL = window.location.href;
    this.LangcurrentURL = this.currentURL.split('/').pop();
    if(this.LangcurrentURL.length == 0){
      this.LangcurrentURL = 'en';
    }
  }

  ngOnInit(): void {
    /* this.siteLocale = window.location.pathname.split('/')[1];
    this.siteLanguage = this.languageList.find(f => f.code === this.siteLocale).label; */
  }
}
