<app-page-title title="{{'TheFirm' | translate}}" background="{{banner}}" subTitle="{{title}}"></app-page-title>

<section *ngFor="let elem of page_elements[0]" class="bg-white">
	<!-- paragraph : titre_and_description -->
	<div *ngIf="elem.type[0].target_id === 'titre_and_description'" class="row">
		<div class="pad">
			<div class="bg-white">
				<h1 *ngIf="elem.field_title.length > 0" class="section_title">{{elem.field_title[0].value}}</h1>
				<div class="w-100" [innerHTML]="elem.field_description[0].value | noSanitize"></div>			
			</div>
		</div>
	</div>
	<!-- paragraph :  paragraph_left_paragraph_right -->
	<div *ngIf="elem.type[0].target_id === 'paragraph_left_paragraph_right'" class="row" >
		<div class="col-md-6 pad-left">
			<h1 class="section_title">{{elem.field_title[0].value}}</h1>
			<div [innerHTML]="elem.field_description[0].value | noSanitize"></div>
		</div>
		<div class="col-md-6 pad-right" style="position: relative;">
			<h1 *ngIf ="elem.field_title_right[0] && elem.field_title_right[0].value !== ''" class="section_title">{{ elem.field_title_right[0].value }}</h1>
			<div [innerHTML]="elem.field_description_right[0].value | noSanitize" class="sans-titre"></div>	
		</div>
	</div>
	<!-- paragraph :  paragraph_left_icon_list_right -->
	<div *ngIf="elem.type[0].target_id === 'paragraph_left_icon_list_right'" class="row">
		<div class="col-md-6 pad-left">
			<h1 class="section_title">{{elem.field_title[0].value}}</h1>
			<div [innerHTML]="elem.field_description[0].value | noSanitize"></div>
		</div>
		<div class="col-md-6 p-5 p-mobile-0 pad-right two_side_content">
			<h1 class="section_title">{{elem.field_title_right[0].value}}</h1>
			<div class="core-values mt-4">
				<div *ngFor="let e of elem.field_list_right">
					<img src="{{back_url}}/{{e.field_list_icon[0].uri[0].url}}" alt="{{back_url}}/{{e.field_list_icon[0].uri[0].url}}">
					<div class="title">{{e.field_title[0].value}}</div>
					<div class="p" [innerHTML]="e.field_description[0].value | noSanitize"></div>
				</div>
			</div>
		</div>	
	</div>
	<!-- paragraph : text_image_background -->
	<div *ngIf="elem.type[0].target_id === 'text_image_background'" class="the_firm" [ngStyle]="{background: 'url('+ getImageUri(elem.field_image[0].uri[0].url) +') top center'}" alt="Firm">
		<div class="pad">
			<div class="row">
				<div class="pad-right-sp">
					<h1 class="section_title">{{elem.field_title[0].value}}</h1>
					<p [innerHTML]="elem.field_description[0].value | noSanitize"></p>
				</div>
			</div>
		</div>
	</div>
	<!-- paragraph : paragraph_white_paragraph_green -->
	<div *ngIf="elem.type[0].target_id === 'paragraph_white_paragraph_green'" class="row">
		<div class="col-md-6 pad-left">
			<h1 class="section_title">{{elem.field_title[0].value}}</h1>
			<p [innerHTML]="elem.field_description[0].value | noSanitize" style="padding-bottom: 0;"></p>
		</div>
		<div class="col-md-6 pad-right services">
			<h1 class="section_title">{{elem.field_title_right[0].value}}</h1>
			<p [innerHTML]="elem.field_description_right[0].value | noSanitize"></p>
		</div>
	</div>
	<!-- paragraph : image_left_text_right -->
	<div *ngIf="elem.type[0].target_id === 'image_left_text_right'">
		<div class="row">
			<div class="col-md-6 bg_img pad-left"[ngStyle]="{background: 'url('+ getImageUri(elem.field_image[0].uri[0].url) +') top center'}"></div>
			<div class="col-md-6 pad-right">
				<h1 class="section_title">{{elem.field_title[0].value}}</h1>
				<div *ngIf="elem.field_description.length > 0" [innerHTML]="elem.field_description[0].value | noSanitize"></div>
			</div>
		</div>
	</div>
	<!-- paragraph : text_left_image_right -->
	<div *ngIf="elem.type[0].target_id === 'text_left_image_right'" class="row">
		<div class="col-md-6 pad-left">
			<h1 class="section_title">{{elem.field_title[0].value}}</h1>
			<p [innerHTML]="elem.field_description[0].value"></p>		
		</div>
		<div class="col-md-6 bg_img pad-right" [ngStyle]="{background: 'url('+ getImageUri(elem.field_image[0].uri[0].url) +')'}" alt=""></div>
	</div>
	<!-- paragraph : text_left_text_right -->
	<div *ngIf="elem.type[0].target_id === 'text_left_text_right'" class="row">
		<div class="col-md-6 p-r pad-left">
			<div class="p-a">
				<h1 class="section_title">{{elem.field_title[0].value}}</h1>
				<p [innerHTML]="elem.field_description[0].value | noSanitize"></p>
			</div>
		</div>
		<div class="col-md-6 pad-right-1">
			<div class="bg-green pad-green">
				<div [innerHTML]="elem.field_list[0].value | noSanitize"></div>
			</div>
		</div>
	</div>
	<!-- paragraph : paragraph_list -->
	<div *ngIf="elem.type[0].target_id === 'paragraph_list'" class="col-md-12 two_side_content pad">
		<h1 class="section_title">{{elem.field_title[0].value}}</h1>
		<div class="paragraph-list mt-4">
			<div *ngFor="let e of elem.field_element">
				<div class="title">{{e.field_title[0].value}}</div>
				<div class="p" [innerHTML]="e.field_description[0].value | noSanitize"></div>
			</div>
		</div>
	</div>
	<!-- paragraph :  image_left_icon_list_right -->
	<div *ngIf="elem.type[0].target_id === 'image_left_icon_list_right'" class="row">
		<div class="col-md-6 bg_img pad-left"[ngStyle]="{background: 'url('+ getImageUri(elem.field_image[0].uri[0].url) +') top center'}"></div>
		<div class="col-md-6 p-5 p-mobile-0 pad-right two_side_content">
			<h1 class="section_title">{{elem.field_title[0].value}}</h1>
			<div class="core-values mt-4">
				<div *ngFor="let e of elem.field_icon_list">
					<img *ngIf="e.field_list_icon.length > 0" src="{{back_url}}/{{e.field_list_icon[0].uri[0].url}}" alt="{{back_url}}/{{e.field_list_icon[0].uri[0].url}}">
					<div *ngIf="e.field_title.length > 0" class="title">{{e.field_title[0].value}}</div>
					<div *ngIf="e.field_description.length > 0" class="p" [innerHTML]="e.field_description[0].value | noSanitize"></div>
				</div>
			</div>
		</div>	
	</div>
</section>

<section class="past-portfolio">
	<div class="row mt-4 no-gutters">
		<div *ngFor="let alliance of alliances" class="col-md-4">
			<div class="portfolio">
				<div class="text-center picture"><img src="{{back_url}}/{{alliance.field_logo[0].uri[0].url}}" class="img-fluid" alt=""></div>
				<div class="title">{{alliance.title[0].value}}</div>
				<div class="separator"></div>
				<p [innerHTML]="alliance.field_description[0].value"></p>
				<!-- <a href="" target="_blank" class="btn btn-info">View more</a> -->
			</div>
		</div>
	</div>
</section>