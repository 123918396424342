import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

@Injectable()
export class AppComponent implements OnInit {
 	title = 'swicorp';
	previousUrl: string;
	currentS : string;
	route: string;
	currentURL='';
	LangcurrentURL: string;
	
	isBrowser: boolean;
	constructor(private spinner: NgxSpinnerService, private renderer: Renderer2, private router: Router, private activeroute: ActivatedRoute,
		public translate: TranslateService, @Inject(DOCUMENT) private document: Document,  @Inject(PLATFORM_ID) platformId: Object) {
	
		this.isBrowser = isPlatformBrowser(platformId);
		this.currentURL = window.location.href;
		this.LangcurrentURL = this.currentURL.split('/').pop();
		if(this.LangcurrentURL){
			this.document.body.classList.add(this.LangcurrentURL);
		}
		else{
			this.document.body.classList.add('en');
		}
		
	 	router.events.subscribe( (event: Event) => {
			
	        if (event instanceof NavigationStart) {
				this.spinner.show();  
	        }

	        if (event instanceof NavigationEnd) {
	        	setTimeout(() => {
			      this.spinner.hide();
			    }, 3000);
	        }

	        if (event instanceof NavigationError) {
	        	console.log('NavigationError');
	            
	        }
		});
		
	}
 	ngOnInit() {
	    this.spinner.show();
	    setTimeout(() => {
	      this.spinner.hide();
		}, 5000);
	}
}
