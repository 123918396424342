import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse ,HttpHeaders } from "@angular/common/http";
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  optionRequete = {
    headers: new HttpHeaders({ 
      'Accept': 'application/vnd.api+json',
    })
  };
  public BACK_URL = environment.back_url;
  constructor(private httpClient: HttpClient) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    /* if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    } */
    return throwError(errorMessage);
  }
  
  public getPages(){
    return this.httpClient.get(this.BACK_URL+'/pages', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getSlides(){
    return this.httpClient.get(this.BACK_URL+'/slides', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getTeamMembers(){
    return this.httpClient.get(this.BACK_URL+'/team', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getAssetClasses(){
    return this.httpClient.get(this.BACK_URL+'/asset-classes', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getHistory(){
    return this.httpClient.get(this.BACK_URL+'/history', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getPastTransactions(){
    return this.httpClient.get(this.BACK_URL+'/past-transactions', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getActivity(){
    return this.httpClient.get(this.BACK_URL+'/activities', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getPortfolio(){
    return this.httpClient.get(this.BACK_URL+'/portfolio', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getAlliances(){
    return this.httpClient.get(this.BACK_URL+'/alliances', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getDownloads(){
    return this.httpClient.get(this.BACK_URL+'/downloads', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getNews(){
    return this.httpClient.get(this.BACK_URL+'/news', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getJobOffers(){
    return this.httpClient.get(this.BACK_URL+'/job-offers', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getMedias(){
    return this.httpClient.get(this.BACK_URL+'/medias', this.optionRequete).pipe(catchError(this.handleError));
  }
  public getOffices(){
    return this.httpClient.get(this.BACK_URL+'/offices', this.optionRequete).pipe(catchError(this.handleError));
  }
}
