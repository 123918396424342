import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  back_url: any;
  base_url : any;
  pages = <any>[];
  news_items = <any>[];
  media_items = <any>[];
  page_elements = <any>[];
  text_image_background_paragraph = <any>[];
  paragraph_white_paragraph_green = <any>[];
  //image_left_text_right_paragraph = <any>[];
  //text_left_image_right_paragraph = <any>[];
  currentURL='';
  LangcurrentURL: string;
  
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private titleService: Title, 
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute ) {
      this.currentURL = window.location.href;
      this.LangcurrentURL = this.currentURL.split('/').pop();
      if(this.LangcurrentURL.length == 0){
        this.LangcurrentURL = 'en';
      }
    }

  ngOnInit(): void {
    /* const lang = localStorage.getItem('locale'); */
    let lang = this.activatedRoute.snapshot.params.lang;
    this.activatedRoute.paramMap.subscribe(params => {
      lang = params.get('lang');
    });
    this.back_url = environment.back_url;
    this.apiService.getPages().subscribe((data)=>{
      this.pages = data;
      if(lang){
        this.pages = this.pages.filter(page => page.langcode[0].value === lang);
      }
      else{
        this.pages = this.pages.filter(page => page.langcode[0].value === 'en');
      }
      for(let page of this.pages){
        // Get the content type page (Home)
        if ((page.nid[0].value === 1) || (page.nid[0].value === 186)){
          this.titleService.setTitle(page.metatag.value.title);
          this.metaService.addTags([
              { name: "title", content: page.metatag.value.title },
              { name: "description", content: page.metatag.value.description },
              { property: "og:title", content: page.metatag.value.og_title },
              { property: "og:description", content: page.metatag.value.og_description },
              { property: "og:url", content:this.document.location },
              { property: "og:type", content: 'website' },
              { property: "og:site_name", content: page.metatag.value.og_site_name },
              { property: "og:image", content:this.base_url +'/assets/images/banner-og.png' },
              { property: "og:image:url", content:this.base_url +'/assets/images/banner-og.png'},
              { property: "og:image:alt", content: 'Swicorp logo' },
              { name: "twitter:card", content: "summary" },
              { name: "twitter:url", content: this.document.location  },
              { name: "twitter:title", content: page.metatag.value.title },
              { name: "twitter:image", content:this.base_url +'/assets/images/banner-og.png' },
              { name: "twitter:image:width", content: "1280" },
              { name: "twitter:image:height", content: "1280" },
              { name: "twitter:image:alt", content: "Swicorp logo" },
          ]);
         // Creating array of field_elements of the page(content type)
         this.page_elements.push(page.field_elements)
        }
      }

    });
    
    this.apiService.getNews().subscribe((data)=>{
      this.news_items = data;
      this.news_items = this.news_items.filter(page => page.langcode[0].value === lang);
      this.news_items.reverse();
    });

    this.apiService.getMedias().subscribe((data)=>{
      this.media_items = data;
      this.media_items = this.media_items.filter(page => page.langcode[0].value === lang);
      this.media_items.reverse();
    });
  }

  getImageUri(url){
    if(url){
      return this.back_url + url;
    }
  }
  isHttps(){
    return (document.location.protocol == 'https:');
  }
}
