<app-page-title
  title="{{ 'ContactCareers' | translate }}"
  background="{{ banner }}"
  subTitle="{{ title }}"
></app-page-title>

<section class="mt-5 pt-5 pb-5" *ngFor="let o of offices_paragraph">
  <div class="container">
    <h1 class="section_title mb-5">{{ o.field_title[0].value }}</h1>
    <div class="row">
      <div class="col-md-7">
        <div
          class="bg_img"
          [ngStyle]="{
            background:
              'url(' +
              getImageUri(o.field_image[0].uri[0].url) +
              ') center center no-repeat',
            backgroundSize: 'cover'
          }"
        ></div>
      </div>
      <!-- Locations block -->
      <div class="col-md-5">
        <div class="locations">
          <div
            *ngFor="let office of offices; let index = index"
            class="box {{ open[index] == 1 ? 'open' : '' }}"
          >
            <div class="header" (click)="open = []; open[index] = 1">
              <i class="fa fa-chevron-right"></i> {{ office.title[0].value }}
            </div>
            <div class="content">
              <div class="row">
                <div
                  class="col-md-12 items"
                  [innerHTML]="office.field_description[0].value | noSanitize"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- specific paragraph : image_left_text_right -- contact section -->
<section
  class="contact_form mt-5 pt-5 pb-5"
  *ngFor="let c of contact_paragraph"
>
  <div class="container">
    <h1 class="section_title mb-5">{{ c.field_title[0].value }}</h1>
    <div class="row pt-5">
      <div class="col-lg-5 col-md-12">
        <div
          class="bg_img"
          [ngStyle]="{
            background:
              'url(' +
              getImageUri(c.field_image[0].uri[0].url) +
              ') center center no-repeat',
            backgroundSize: 'cover'
          }"
        ></div>
      </div>
      <!-- Contact form -->
      <div class="col-lg-7 col-md-12">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="row no-gutters">
            <div class="col-md-2">
              <label class="col-form-label">{{ "Name" | translate }} *</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="name"
                pattern="[a-zA-Z،-٩\- ]*"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
              />
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-2">
              <label class="col-form-label">{{ "Mobile" | translate }}</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                formControlName="mobile"
                class="form-control"
              />
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-2">
              <label class="col-form-label">{{ "Email" | translate }} *</label>
            </div>
            <div class="col-md-10">
              <input
                type="email"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                class="form-control"
              />
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-2">
              <label class="col-form-label"
                >{{ "Subject" | translate }} *</label
              >
            </div>
            <div class="col-md-10">
              <input
                type="text"
                formControlName="subject"
                pattern="[a-zA-Z،-٩\- ]*"
                [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
                class="form-control"
              />
            </div>
          </div>

          <div class="row no-gutters mb-4">
            <div class="col-md-2">
              <label class="col-form-label"
                >{{ "Message" | translate }} *</label
              >
            </div>
            <div class="col-md-10">
              <textarea
                type="text"
                class="form-control"
                formControlName="message"
                [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                rows="9"
              ></textarea>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-md-2"></div>
            <div class="col-md-10 pb-3">
              <!-- <re-captcha siteKey="6LeSH6sZAAAAACjI5gmJ9-8YABRqhU08-Fw2P4XJ"></re-captcha> -->
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-auto pt-2">
              (*) {{ "RequiredFields" | translate }}
            </div>
            <div class="col-md text-center"></div>
            <div class="col-md-6 text-right">
              <button type="submit" class="btn btn-info">
                {{ "Submit" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- Other paragraphs section -->
<section *ngFor="let elem of page_elements[0]">
  <!-- paragraph : text_image_background -->
  <div
    *ngIf="elem.type[0].target_id === 'text_image_background'"
    class="the_firm"
    [ngStyle]="{
      background:
        'url(' + getImageUri(elem.field_image[0].uri[0].url) + ') top center'
    }"
    alt="Firm"
  >
    <div class="pad">
      <div class="row">
        <div class="pad-right-sp">
          <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
          <p [innerHTML]="elem.field_description[0].value | noSanitize"></p>
        </div>
      </div>
    </div>
  </div>
  <!-- paragraph : paragraph_white_paragraph_green -->
  <div
    *ngIf="elem.type[0].target_id === 'paragraph_white_paragraph_green'"
    class="row"
  >
    <div class="col-md-6 pad-left">
      <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
      <p
        [innerHTML]="elem.field_description[0].value | noSanitize"
        style="padding-bottom: 0"
      ></p>
    </div>
    <div class="col-md-6 pad-right services">
      <h1 class="section_title">{{ elem.field_title_right[0].value }}</h1>
      <p [innerHTML]="elem.field_description_right[0].value | noSanitize"></p>
    </div>
  </div>
  <!-- paragraph : text_left_image_right -->
  <div *ngIf="elem.type[0].target_id === 'text_left_image_right'" class="row">
    <div class="col-md-6 pad-left">
      <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
      <p [innerHTML]="elem.field_description[0].value"></p>
    </div>
    <div
      class="col-md-6 bg_img pad-right"
      [ngStyle]="{
        background: 'url(' + getImageUri(elem.field_image[0].uri[0].url) + ')'
      }"
      alt=""
    ></div>
  </div>
  <!-- paragraph : text_left_text_right -->
  <div *ngIf="elem.type[0].target_id === 'text_left_text_right'" class="row">
    <div class="col-md-6 p-r pad-left">
      <div class="p-a">
        <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
        <p [innerHTML]="elem.field_description[0].value | noSanitize"></p>
      </div>
    </div>
    <div class="col-md-6 pad-right-1">
      <div class="bg-green pad-green">
        <div [innerHTML]="elem.field_list[0].value | noSanitize"></div>
      </div>
    </div>
  </div>
  <!-- paragraph : titre_and_description -->
  <div *ngIf="elem.type[0].target_id === 'titre_and_description'" class="row">
    <div class="pad">
      <div class="bg-white">
        <h1 *ngIf="elem.field_title.length > 0" class="section_title">
          {{ elem.field_title[0].value }}
        </h1>
        <div
          class="w-100"
          [innerHTML]="elem.field_description[0].value | noSanitize"
        ></div>
      </div>
    </div>
  </div>
  <!-- paragraph : paragraph_left_paragraph_right -->
  <div
    *ngIf="elem.type[0].target_id === 'paragraph_left_paragraph_right'"
    class="row"
  >
    <div class="col-md-6 pad-left">
      <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
      <div [innerHTML]="elem.field_description[0].value | noSanitize"></div>
    </div>
    <div class="col-md-6 pad-right" style="position: relative">
      <h1
        *ngIf="
          elem.field_title_right[0] && elem.field_title_right[0].value !== ''
        "
        class="section_title"
      >
        {{ elem.field_title_right[0].value }}
      </h1>
      <div
        [innerHTML]="elem.field_description_right[0].value | noSanitize"
        class="sans-titre"
      ></div>
    </div>
  </div>
  <!-- paragraph :  paragraph_left_icon_list_right -->
  <div
    *ngIf="elem.type[0].target_id === 'paragraph_left_icon_list_right'"
    class="row"
  >
    <div class="col-md-6 pad-left">
      <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
      <div [innerHTML]="elem.field_description[0].value | noSanitize"></div>
    </div>
    <div class="col-md-6 p-5 p-mobile-0 pad-right two_side_content">
      <h1 class="section_title">{{ elem.field_title_right[0].value }}</h1>
      <div class="core-values mt-4">
        <div *ngFor="let e of elem.field_list_right">
          <img
            *ngIf="e.field_list_icon.length > 0"
            src="{{ back_url }}/{{ e.field_list_icon[0].uri[0].url }}"
            alt="{{ back_url }}/{{ e.field_list_icon[0].uri[0].url }}"
          />
          <div *ngIf="e.field_title.length > 0" class="title">
            {{ e.field_title[0].value }}
          </div>
          <div
            *ngIf="e.field_description.length > 0"
            class="p"
            [innerHTML]="e.field_description[0].value | noSanitize"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- paragraph : paragraph_list -->
  <div
    *ngIf="elem.type[0].target_id === 'paragraph_list'"
    class="col-md-12 two_side_content pad"
  >
    <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
    <div class="paragraph-list mt-4">
      <div *ngFor="let e of elem.field_element">
        <div class="title">{{ e.field_title[0].value }}</div>
        <div
          class="p"
          [innerHTML]="e.field_description[0].value | noSanitize"
        ></div>
      </div>
    </div>
  </div>
  <!-- paragraph :  image_left_icon_list_right -->
  <div
    *ngIf="elem.type[0].target_id === 'image_left_icon_list_right'"
    class="row"
  >
    <div
      class="col-md-6 bg_img pad-left"
      [ngStyle]="{
        background:
          'url(' + getImageUri(elem.field_image[0].uri[0].url) + ') top center'
      }"
    ></div>
    <div class="col-md-6 p-5 p-mobile-0 pad-right two_side_content">
      <h1 class="section_title">{{ elem.field_title[0].value }}</h1>
      <div class="core-values mt-4">
        <div *ngFor="let e of elem.field_icon_list">
          <img
            *ngIf="e.field_list_icon.length > 0"
            src="{{ back_url }}/{{ e.field_list_icon[0].uri[0].url }}"
            alt="{{ back_url }}/{{ e.field_list_icon[0].uri[0].url }}"
          />
          <div *ngIf="e.field_title.length > 0" class="title">
            {{ e.field_title[0].value }}
          </div>
          <div
            *ngIf="e.field_description.length > 0"
            class="p"
            [innerHTML]="e.field_description[0].value | noSanitize"
          ></div>
        </div>
      </div>
    </div>
  </div>
</section>
