<div class="slider">
	<owl-carousel-o [options]="customOptions">
		<ng-container *ngFor="let s of slides">
			<ng-template carouselSlide [id]="s.id">
				<img src="{{back_url}}{{s.field_home_slider_image[0].uri[0].url}}" alt="Swicorp">
				<div class="content">
					<h1 [innerHTML]="s.field_description[0].value | noSanitize"></h1>
				</div>
				<div class="container d-none d-sm-block">
					<div class="row">
						<div class="col-4" *ngFor="let element of s.field_elements_slide">
							<div [innerHTML]="element.field_value[0].value | noSanitize" class="value"></div>
							<div [innerHTML]="element.field_title[0].value | noSanitize" class="label"></div>
						</div>
					</div>
				</div>
			</ng-template>   
		</ng-container>
	</owl-carousel-o>
</div>