import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  slides = <any>[];
  back_url: any;
  lang: any;

	customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay:false,
    autoplayTimeout: 6000,
    navText: ['', ''],
    nav: true,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
    },
  }
  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.lang = params.get('lang');
    });
    this.back_url = environment.back_url;
    this.apiService.getSlides().subscribe((data)=>{
      this.slides = data;
      this.slides = this.slides.filter(page => page.langcode[0].value === this.lang);
    });
  }
}
