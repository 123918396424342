import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  q: string;
  result: any;
  back_url: any;
  lang: any;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.q = params.get('q');
      this.back_url = environment.back_url;

      this.http
        .get(
          this.back_url +
            '/jsonapi/index/swicorp_content_index?filter[fulltext]=' +
            this.q
        )
        .pipe()
        .subscribe((data) => {
          this.result = data;
        });
    });

    this.lang = this.activatedRoute.snapshot.params.lang;
    this.activatedRoute.paramMap.subscribe((params) => {
      this.lang = params.get('lang');
    });
  }

  getPathForType(type, alias, lang) {
    //  Download

    // /cma-disclosures + type

    //  Job offer

    // /careers + county

    switch (type) {
      case 'node--team':
        return '/team/' + lang;
        break;

      case 'node--history':
        return '/about/' + lang;
        break;

      case 'node--activity':
        return '/private-equity/' + lang;
        break;

      case 'node--home_slider':
        return '/' + lang;
        break;

      case 'node--media':
        return '/media-center/' + alias + '/' + lang;
        break;

      case 'node--alliance':
        return '/alliances/' + lang;
        break;

      case 'node--news':
        return '/news/' + alias + '/' + lang;
        break;

      case 'node--portofolio_element':
        return '/private-equity/' + lang;
        break;

      case 'node--office':
        return '/contact/' + lang;
        break;

      case 'node--asset_classes':
        return '/asset-management/' + lang;
        break;

      case 'node--past_transaction':
        return '/investment-banking/' + lang;
        break;

      case 'node--job_offer':
        return '/careers/' + lang;
        break;

      default:
        return '';
        break;
    }
  }
}
